<template>
  <Layout :top-bar-title="title" :sections="sections" :activeBackButton="true" :baseLayoutInfoUrl="'/base-layout-info/'">
    <h3 class="ml-2">{{ $t('general.elementsSelectedTitle') }}</h3>
    <ul class="ml-4">
      <li v-for="(campaignDescription, i) in getCampaignDescriptions(this.$route.query.ids)" :key="i">
        {{campaignDescription}}
      </li>
    </ul>
    <v-form>
      <CustomView
        viewName="update_multiple_campaign"
        v-model="campaignSet"
        :options="options"
        :custom-component-catalog="customComponentCatalog"
      />
    </v-form>
    <v-btn
      class="ma-2"
      :loading="loadingSaveButton"
      :disabled="loadingSaveButton"
      color="primary"
      @click="updateCampaigns"
    >
      <v-icon left dark>fa-save</v-icon>
      {{ $t('general.save') }}
    </v-btn>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import CustomView from '@/lib/uncustomui/components/CustomView'
import sections from './defaultNavigationDrawerSections'
import { BASE_URL, RESPONSE_LEVEL, MULTIPLE_UPDATE_ID } from '@/variables'
import apiClient from '@/lib/unlogin/store/apiclient'
import { mapActions, mapGetters } from 'vuex'
import {
  createSuccessNotification,
  createWarningNotification,
  createErrorNotification
} from '@/lib/unnotificationsqueue'
import InternalGroupingView from './InternalGroupingView'
import CampaignDatesRangeView from '@/apps/dido/components/CampaignDatesRangeView'

export default {
  name: 'UpdateMultipleCampaign',
  components: { CustomView, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('campaigns.editMultipleCampaigns'),
      loadingSaveButton: false,
      customComponentCatalog: {
        'CampaignDatesRangeView': CampaignDatesRangeView,
        'InternalGroupingView': InternalGroupingView
      },
      campaignSet: {
        description: '(' + this.$t('general.noChanges') + ')',
        notes: '(' + this.$t('general.noChanges') + ')',
        status: MULTIPLE_UPDATE_ID,
        banner: MULTIPLE_UPDATE_ID,
        rate: MULTIPLE_UPDATE_ID,
        topic: MULTIPLE_UPDATE_ID,
        dates_verbose_name: { order_date: this.$t('campaigns.orderDate'), store_promo_date: this.$t('campaigns.storeDate') },
        store_promo_date: '{"bounds": "[)", "lower": "", "upper": ""}',
        order_date: '{"bounds": "[)", "lower": "", "upper": ""}',
        show_order_date: false
      },
      options: {
        context: {
          campaignId: MULTIPLE_UPDATE_ID,
          serverUrl: BASE_URL
        }
      }
    }
  },
  computed: {
    ...mapGetters('campaigns', ['getCampaignDescriptions'])
  },
  methods: {
    ...mapActions({ addNotification: 'addNotification' }),
    updateCampaigns () {
      this.loadingSaveButton = true

      let CampaignIdsString = this.$route.query.ids
      if (CampaignIdsString === undefined) {
        return
      }
      let CampaignIds = CampaignIdsString.split(',')

      let updateResponsesMessages = []
      let responseLevel = RESPONSE_LEVEL.SUCCESS

      let updatedFields = this.getUpdatedFields()

      let promises = []

      for (let CampaignId of CampaignIds) {
        let promise = apiClient
          .patch(`/campaigns/${CampaignId}/`, updatedFields)
          .then(response => {
            if (response.data.level === RESPONSE_LEVEL.WARNING) {
              updateResponsesMessages.push(`${this.$t('campaigns.campaignDetail')} #${CampaignId}: ${response.data.message}`)
              responseLevel = responseLevel === RESPONSE_LEVEL.ERROR ? RESPONSE_LEVEL.ERROR : RESPONSE_LEVEL.WARNING
            }
          })
          .catch(error => {
            if (error.response.data.hasOwnProperty('level')) {
              updateResponsesMessages.push(`${this.$t('campaigns.campaignDetail')} #${CampaignId}: ${error.response.data.message}`)
              responseLevel = responseLevel === RESPONSE_LEVEL.ERROR ? RESPONSE_LEVEL.ERROR : error.response.data.level
            } else {
              responseLevel = RESPONSE_LEVEL.ERROR
              updateResponsesMessages.push(`${this.$t('campaigns.campaignDetail')} #${CampaignId}: ${this.$t('dialogs.errorWhenSaving')}`)
            }
          })
        promises.push(promise)
      }

      Promise.all(promises).then(() => {
        this.loadingSaveButton = false

        if (responseLevel === RESPONSE_LEVEL.SUCCESS) {
          this.$router.push({ name: 'CampaignList' })
        } else {
          this.printResponse(responseLevel, updateResponsesMessages)
        }
      })
    },
    getUpdatedFields () {
      let updatedFields = {}
      for (const [key, value] of Object.entries(this.campaignSet)) {
        if (value !== '(' + this.$t('general.noChanges') + ')' && value !== MULTIPLE_UPDATE_ID && value !== '{"bounds": "[)", "lower": "", "upper": ""}') {
          updatedFields[key] = value
        }
      }
      return updatedFields
    },
    printResponse (responseLevel, updateResponsesMessages) {
      switch (responseLevel) {
        case RESPONSE_LEVEL.SUCCESS:
          this.addNotification(createSuccessNotification(this.$t('campaigns.editedSuccessfully')))
          break
        case RESPONSE_LEVEL.WARNING:
          this.addNotification(createWarningNotification(updateResponsesMessages))
          break
        case RESPONSE_LEVEL.ERROR:
          this.addNotification(createErrorNotification(updateResponsesMessages))
          break
      }
    },
    getDatesInfo () {
      let CampaignIdsString = this.$route.query.ids
      if (CampaignIdsString === undefined) {
        return
      }
      let firstCampaignId = CampaignIdsString.split(',')[0]

      apiClient
        .get(`/campaigns/${firstCampaignId}/`)
        .then(response => {
          this.campaignSet.dates_verbose_name = response.data['dates_verbose_name']
          this.campaignSet.show_order_date = response.data['show_order_date']
        })
    }
  },
  mounted () {
    this.getDatesInfo()
  }
}
</script>

<style scoped>

</style>
