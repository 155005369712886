import { validationMixin } from '@/apps/core/mixins/validations'
import { IS_CAC, IS_PNG } from '@/variables'
import moment from 'moment'

export default {
  name: 'DateRangePickerInField',
  props: {
    isRange: {
      type: Boolean,
      default: true,
      description: 'Determinate if can select date range or just one date'
    },
    label: String,
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    bounds: String,
    validateDate: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin],
  data () {
    return {
      openCalendar: false,
      currentLocale: IS_PNG ? 'en' : 'es'
    }
  },
  computed: {
    internalRange: {
      get () {
        if (this.$props.start) {
          const start = this.$props.start
          if (this.$props.end) {
            let end = this.$props.end
            if (this.$props.bounds === '[)') end = moment(end).subtract(1, 'days').format('YYYY-MM-DD')
            return [start, end]
          } else {
            return [this.$props.start]
          }
        }
        return []
      },
      /**
   * Redirects the internalRange array asignations to envents for (start / end).
   * The vuetify range picker uses an array as model with length 1 (only start) or 2 (range complete).
   * @param newValue
   */
      set (newValue) {
        if (newValue.length === 1) {
          this.$emit('update:start', newValue[0])
          this.$emit('update:end', '')
        } else if (newValue.length === 2) {
          this.$emit('update:end', newValue[1])
        }
      }
    },
    internalValue: {
    /**
   * Used to get the date value in case is not a range picker.
   * @returns {string|any}
   */
      get () {
        if (this.$props.start) {
          return this.$props.start
        } else {
          return ''
        }
      },
      /**
   * Used to set the date value in case is not a range picker.
   * @param date
   */
      set (date) {
        this.$emit('update:start', date)
      }
    },
    /**
   * Parse the date to DD/MM/YYYY format to display in the text field. To do this we do a shallow copy of the
   * real array.
   * @returns {String} String parsed with the date.
   */
    dateRangeText () {
      if (this.internalRange.length > 0) {
        let internalRangeCopy = this.internalRange.slice()
        internalRangeCopy.forEach((dateToBeParsed, index) => {
          const date = new Date(dateToBeParsed).toISOString().substr(0, 10)
          const [year, month, day] = date.split('-')
          internalRangeCopy[index] = `${day}/${month}/${year}`
        })
        return internalRangeCopy.join(' - ')
      }
      return ''
    },

    /**
   * Parse the date to DD/MM/YYYY format to display in the text field. To do this we do a shallow copy of the
   * real array.
   * @returns {String} String parsed with the date.
   */
    dateText () {
      if (this.internalValue) {
        const date = new Date(this.internalValue).toISOString().substr(0, 10)
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      }
    },

    /**
     * Get minimum allowed date/month
     * @returns {string}
     */
    getStartDate () {
      if (IS_CAC && this.$route.name === 'CampaignTemplateDetail') {
        let startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
        return startOfMonth
      } else {
        return '1970-01-01'
      }
    },

    /**
     * Get maximum allowed date/month
     * @returns {string}
     */
    getEndDate () {
      return '2100-12-31'
    }
  },
  methods: {
    /**
   * Store the value of the date selected. In case two dates have been selected its going to close the date picker.
   * @param value {Array} Contains the dates.
   */
    onChange (value) {
      if (this.$props.isRange) {
        this.internalRange = value
      } else {
        this.internalValue = value
      }
      if (value.length === 2) {
        this.openCalendar = !this.openCalendar
        this.$emit('save')
      } else if (!this.$props.isRange) {
        this.openCalendar = !this.openCalendar
        this.$emit('save')
      }
    },
    /**
   * Reset the values of the selected dates. The event its emitted to CampaignDatesRangesView.
   */
    onCleanDate () {
      this.openCalendar = !this.openCalendar
      this.$emit('update:start', null)
      this.$emit('update:end', null)
      this.$emit('save')
    }
  }
}
