<template>
  <v-layout
    row
    wrap
  >
    <DateRangePickerInField
      :label="labelMainDate"
      :start.sync="startMainDate"
      :end.sync="endMainDate"
      :validateDate="true"
      :remove-padding="removePadding"
    />
    <v-layout
      row
      wrap
      v-if="showSecondaryDate"
      class="px-3"
    >
      <DateRangePickerInField
        :label="labelSecondaryDate"
        :start.sync="startSecondaryDate"
        :end.sync="endSecondaryDate"
        :validateDate="true"
        :remove-padding="removePadding"
      />
      <template v-if="showSecondaryDateButtons">
        <v-btn class="ma-2" tile outlined color="primary"
               @click="subtractDaysInSecondaryDate(seven)">{{ -seven}}
        </v-btn>
        <v-btn class="ma-2" tile outlined color="primary"
               @click="subtractDaysInSecondaryDate(fourteen)">{{ -fourteen}}
        </v-btn>
        <v-btn class="ma-2" tile outlined color="primary"
               @click="subtractDaysInSecondaryDate(twentyEight)">{{ -twentyEight}}
        </v-btn>
        <v-btn class="ma-2" tile outlined color="primary"
               @click="subtractDaysInSecondaryDate(fortyTwo)">{{ -fortyTwo}}
        </v-btn>
      </template>
    </v-layout>
  </v-layout>
</template>

<script>
import DateRangePickerInField from '@/apps/core/components/forms/DateRangePickerInField/DateRangePickerInField.vue'
import moment from 'moment'
import Lget from 'lodash/get'
import Lset from 'lodash/set'

export default {
  name: 'CampaignDatesRangeView',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    removePadding: {
      type: Boolean,
      default: false
    },
    showSecondaryDate: {
      type: Boolean,
      default: false
    },
    showSecondaryDateButtons: {
      type: Boolean,
      default: true
    },
    mainDateName: {
      type: String,
      default: 'store_promo_date'
    },
    secondaryDateName: {
      type: String,
      default: 'order_date'
    }
  },
  data () {
    return {
      lower: 'lower',
      upper: 'upper',
      seven: 7,
      fourteen: 14,
      twentyEight: 28,
      fortyTwo: 42,
      DATE_FORMAT_UPDATE: 'YYYY-MM-DD'
    }
  },
  components: { DateRangePickerInField },
  computed: {
    currentDate () {
      return { 'bounds': '[)', 'lower': '', 'upper': '' }
    },
    labelMainDate () {
      return `* ${this.value.dates_verbose_name[this.mainDateName]}`
    },
    labelSecondaryDate () {
      return `* ${this.value.dates_verbose_name[this.secondaryDateName]}`
    },
    startMainDate: {
      get: function () {
        return this.getDateByCurrentItem(this.mainDateName, this.lower)
      },
      set: function (value) {
        const date = this.setDateByCurrentItem(this.mainDateName, this.lower, value)
        let emitItem = { ...this.value }
        emitItem[this.mainDateName] = JSON.stringify(date)
        this.$emit('input', emitItem)
      }
    },
    endMainDate: {
      get: function () {
        return this.getDateByCurrentItem(this.mainDateName, this.upper)
      },
      set: function (value) {
        const date = this.setDateByCurrentItem(this.mainDateName, this.upper, value)
        if (value === '') return
        this.$emit('input', this.updateMainAndSecondaryDate(date))
      }
    },
    startSecondaryDate: {
      get: function () {
        return this.getDateByCurrentItem(this.secondaryDateName, this.lower)
      },
      set: function (value) {
        const date = this.setDateByCurrentItem(this.secondaryDateName, this.lower, value)
        let emitItem = { ...this.value }
        emitItem[this.secondaryDateName] = JSON.stringify(date)
        this.$emit('input', emitItem)
      }
    },
    endSecondaryDate: {
      get: function () {
        return this.getDateByCurrentItem(this.secondaryDateName, this.upper)
      },
      set: function (value) {
        const date = this.setDateByCurrentItem(this.secondaryDateName, this.upper, value)
        if (value === '') return
        let emitItem = { ...this.value }
        emitItem[this.secondaryDateName] = JSON.stringify(date)
        this.$emit('input', emitItem)
      }
    }
  },
  methods: {
    Lget: Lget,
    getDateByCurrentItem (keyCurrentItem, key) {
      const date = this.value[keyCurrentItem] ? JSON.parse(this.value[keyCurrentItem]) : this.currentDate
      return Lget(date, key)
    },
    setDateByCurrentItem (keyCurrentItem, key, value) {
      const date = this.value[keyCurrentItem] ? JSON.parse(this.value[keyCurrentItem]) : this.currentDate
      var updateDate = date
      if (key === this.lower) {
        updateDate = Lset(date, key, value)
        updateDate = Lset(updateDate, this.upper, '')
      } else if (key === this.upper) {
        var lowerDate = Lget(date, this.lower)

        if (lowerDate > value) {
          updateDate = Lset(updateDate, this.lower, value)
          updateDate = Lset(updateDate, this.upper, lowerDate)
        } else {
          updateDate = Lset(updateDate, this.lower, lowerDate)
          updateDate = Lset(updateDate, this.upper, value)
        }
      }
      return updateDate
    },
    subtractDaysInSecondaryDate (days) {
      if (this.value[this.mainDateName] == null) return

      const currentSecondaryDateLower = Lget(JSON.parse(this.value[this.mainDateName]), this.lower)
      const currentSecondaryDateUpper = Lget(JSON.parse(this.value[this.mainDateName]), this.upper)

      const subtractSecondaryDateLower = moment(currentSecondaryDateLower).subtract(days, 'days').format(this.DATE_FORMAT_UPDATE)
      const subtractSecondaryDateUpper = moment(currentSecondaryDateUpper).subtract(days, 'days').format(this.DATE_FORMAT_UPDATE)

      const dateLower = Lset(JSON.parse(this.value[this.secondaryDateName]), this.lower, subtractSecondaryDateLower)
      const dateUpper = Lset(dateLower, this.upper, subtractSecondaryDateUpper)
      let emitItem = { ...this.value }
      emitItem[this.secondaryDateName] = JSON.stringify(dateUpper)
      this.$emit('input', emitItem)
    },
    updateMainAndSecondaryDate (date) {
      let argsDate = { ...this.value }
      argsDate[this.mainDateName] = JSON.stringify(date)
      if (this.value[this.mainDateName] && this.value[this.secondaryDateName] == null) {
        argsDate[this.secondaryDateName] = JSON.stringify(date)
      }
      return argsDate
    }
  }
}
</script>
