<template>
  <div>
    <v-row>
      <v-col cols="10">
        <v-text-field
          :label="$t('general.name')"
          :placeholder="$t('campaigns.campaignNoDistributorSet')"
          disabled
          v-model="grouping_name"
          />
      </v-col>
      <v-col cols="1 ml-10">
        <v-dialog v-model="dialog" width="1000">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              v-on="on"
              fab
              small
            >
              <v-icon>fa-plus</v-icon>
            </v-btn>
          </template>
          <InternalGroupingForm :value="this.currentCampaign" @save="save" @close="close" @input="updateInternalGrouping($event)" />

          <slot name="form" :close="close"/>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <template>
          <v-expansion-panels v-if="internalGroupingExists">
            <v-expansion-panel>
              <v-expansion-panel-header>{{ $t('campaigns.campaignAssociatedDistributors') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-slot:actions>
                  <v-icon color="primary">fa-info-circle</v-icon>
                </template>
                <span>{{ $t('general.clients') }} ({{internalGroupingDistributorsCount}}) en {{grouping_name}}: </span>
                <span>{{internalGroupingDistributors}}</span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import InternalGroupingForm from '../components/forms/InternalGroupingForm'

export default {
  name: 'InternalGroupingView',
  props: {
    object: Object
  },
  components: { InternalGroupingForm },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState('campaigns', { currentCampaign: 'currentItem' }),
    ...mapState('internalGrouping', { internalGrouping: 'currentItem' }),
    internalGroupingExists () {
      return this.internalGrouping.name !== undefined && this.internalGrouping.name !== null
    },
    grouping_name: {
      get () {
        return this.internalGroupingExists ? this.internalGrouping.name : ''
      }
    },
    internalGroupingDistributors: {
      get () {
        return this.internalGroupingExists ? this.internalGrouping.internal_grouping_distributors.join(', ') : ''
      }
    },
    internalGroupingDistributorsCount: {
      get () {
        return this.internalGroupingExists ? this.internalGrouping.internal_grouping_distributors.length : 0
      }
    }
  },
  methods: {
    ...mapActions('internalGrouping', ['getCampaignInternalGrouping']),
    save () {
      this.getCampaignInternalGrouping(this.currentCampaign.banner)
    },
    close () {
      this.dialog = false
    },
    updateInternalGrouping (value) {
      this.getCampaignInternalGrouping(value.banner)
        .then(() => {
          this.$emit('input', value)
        })
    }
  }
}
</script>
