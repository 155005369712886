<template>
  <v-form @submit.prevent="saveForm" ref="form">
    <v-card>
      <CustomView
        view-name="internal_grouping_form_view"
        :value="this.newItem"
        @input="updateItem($event)"
        :options="options"/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="cancel" color="primary" text @click="closeForm">Cancelar</v-btn>
        <v-btn
          class="save"
          color="primary"
          :loading="loadingSave"
          :disabled="loadingSave"
          @click="loader = 'loadingSave'">{{$t('general.add')}}</v-btn>
      </v-card-actions>
      <PrettyAlert :text="textAlert" v-model="showAlert"/>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from '@/apps/core/mixins/validations'
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import CustomView from '@/lib/uncustomui/components/CustomView'
import { RESPONSE_LEVEL } from '@/variables'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'

export default {
  name: 'InternalGroupingForm',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: { PrettyAlert, CustomView },
  mixins: [validationMixin],
  data () {
    return {
      textAlert: '',
      showAlert: false,
      options: {},
      loader: null,
      loadingSave: false
    }
  },
  computed: {
    ...mapState('internalGrouping', ['newItem'])
  },
  methods: {
    ...mapActions('internalGrouping', ['createCampaignInternalGrouping', 'updateNewItem']),
    ...mapActions({ addNotification: 'addNotification' }),
    closeForm () {
      this.$refs.form.reset()
      this.$emit('close')
      this.stopLoadingButton()
    },
    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },
    saveForm () {
      if (this.$refs.form.validate()) {
        this.createCampaignInternalGrouping(this.newItem)
          .then(response => {
            if (response.status === RESPONSE_LEVEL.ERROR) {
              this.addNotification(createErrorNotification(response.messages))
            } else {
              this.$emit('save')
              this.$refs.form.reset()
              this.closeForm()
              if (response.status === RESPONSE_LEVEL.SUCCESS) {
                this.addNotification(createSuccessNotification(response.messages))
              } else {
                this.addNotification(createWarningNotification(response.messages))
              }
              this.$emit('input', { ...this.value, banner: response.banner })
            }
            this.stopLoadingButton()
          })
      }
    },
    updateItem (items) {
      this.updateNewItem({ ...this.newItem, ...items })
    },
    stopLoadingButton () {
      this.loadingSave = false
    }
  },
  mounted () {
    this.updateNewItem({})
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      if (l === 'loadingSave') {
        this.saveForm(true)
      }
      this.loader = null
    }
  }
}
</script>
